/*

Tailwind - The Utility-First CSS Framework

A project by Adam Wathan (@adamwathan), Jonathan Reinink (@reinink),
David Hemphill (@davidhemphill) and Steve Schoger (@steveschoger).

Welcome to the Tailwind config file. This is where you can customize
Tailwind specifically for your project. Don't be intimidated by the
length of this file. It's really just a big JavaScript object and
we've done our very best to explain each section.

View the full documentation at https://tailwindcss.com.

|-------------------------------------------------------------------------------
| Colors                                    https://tailwindcss.com/docs/colors
|-------------------------------------------------------------------------------
|
| Here you can specify the colors used in your project. To get you started,
| we've provided a generous palette of great looking colors that are perfect
| for prototyping, but don't hesitate to change them for your project. You
| own these colors, nothing will break if you change everything about them.
|
| We've used literal color names ("red", "blue", etc.) for the default
| palette, but if you'd rather use functional names like "primary" and
| "secondary", or even a numeric scale like "100" and "200", go for it.
|
*/

module.exports = {
  mode: 'jit',
  /*
    |-----------------------------------------------------------------------------
    | Advanced Options         https://tailwindcss.com/docs/configuration#options
    |-----------------------------------------------------------------------------
    |
    | Here is where you can tweak advanced configuration options. We recommend
    | leaving these options alone unless you absolutely need to change them.
    |
    */
  purge: [
    '../Views/**/*.cshtml',
    './src/**/*.html',
    './src/**/*.vue',
    './src/**/*.ts',
    './src/**/*.css',
    './src/**/*.less',
    './safelist.txt'
  ],
  prefix: 'u-',
  theme: {
    /*
      |-----------------------------------------------------------------------------
      | Colors                                  https://tailwindcss.com/docs/colors
      |-----------------------------------------------------------------------------
      |
      | The color palette defined above is also assigned to the "colors" key of
      | your Tailwind config. This makes it easy to access them in your CSS
      | using Tailwind's config helper. For example:
      |
      | .error { color: theme('colors.red') }
      |
      */
    inset: {
      0: '0',
      auto: 'auto',
      '1/2': '50%',
      '8p': '8px',
      '10p': '10px',
      '15p': '15px',
      '40p': '40px',
      '45p': '45px',
      '100p': '100px',
      '135p': '135px'
    },

    colors: {
      transparent: 'transparent',
      'current-color': 'currentColor',
      inherit: 'inherit',
      black: '#000000',
      white: '#ffffff',
      'bright-2': '#C9DFDB',
      'bright-3': '#CFD0F3',
      'bright-4': '#FFF1A2',
      'bright-6': '#FF9B54',
      'dark-1': '#1A1A1A',
      'dark-2': 'rgba(0, 0, 0, .25)',
      'dark-3': 'rgba(0, 0, 0, .3)',
      'dark-4': 'rgba(0, 0, 0, .5)',
      'dark-5': 'rgba(0, 0, 0, .6)',
      'dark-6': 'rgba(0, 0, 0, .4)',
      'dark-20': 'rgba(0, 0, 0, .2)',
      'grey-1': '#F3F3F3',
      'grey-11': '#DEDEDE',
      'grey-2': '#C4C4C4',
      'grey-3': '#868685',
      'grey-4': '#F6F6F6',
      'grey-5': 'rgba(255, 255, 255, .3)',
      'grey-6': 'rgba(255, 255, 255, .5)',
      'grey-7': 'rgba(255, 255, 255, .75)',
      'grey-8': 'rgba(255, 255, 255, .6)',
      invalid: '#C83333',
      'medium-invalid': '#f96666',
      'light-invalid': '#ef96a1',
      cbcbcb: '#cbcbcb',
      a9a9a9: '#a9a9a9',
      '9e9e9e': '#9e9e9e'
    },

    /*
      |-----------------------------------------------------------------------------
      | Screens                      https://tailwindcss.com/docs/responsive-design
      |-----------------------------------------------------------------------------
      |
      | Screens in Tailwind are translated to CSS media queries. They define the
      | responsive breakpoints for your project. By default Tailwind takes a
      | "mobile first" approach, where each screen size represents a minimum
      | viewport width. Feel free to have as few or as many screens as you
      | want, naming them in whatever way you'd prefer for your project.
      |
      | Tailwind also allows for more complex screen definitions, which can be
      | useful in certain situations. Be sure to see the full responsive
      | documentation for a complete list of options.
      |
      | Class name: .{screen}:{utility}
      |
      */

    screens: {
      'sub-md': { min: 0, max: '759px' },
      md: '760px',
      'sub-lg': { min: 0, max: '1024px' },
      lg: '1025px',
      xl: '1500px'
    },
    /*
      |-----------------------------------------------------------------------------
      | Fonts                                    https://tailwindcss.com/docs/fonts
      |-----------------------------------------------------------------------------
      |
      | Here is where you define your project's font stack, or font families.
      | Keep in mind that Tailwind doesn't actually load any fonts for you.
      | If you're using custom fonts you'll need to import them prior to
      | defining them here.
      |
      | By default we provide a native font stack that works remarkably well on
      | any device or OS you're using, since it just uses the default fonts
      | provided by the platform.
      |
      | Class name: .font-{name}
      |
      */

    fontFamily: {
      'display-large': [
        'Reckless Neue Light',
        'Helvetica Neue LT W01_65 Md',
        'Arial',
        'sans-serif'
      ],
      display: [
        'Helvetica Neue LT W01_65 Md',
        'Arial',
        'sans-serif'
      ],
      body: [
        'Helvetica Neue LT W01_55 Roman',
        'Arial',
        'sans-serif'
      ]
    },

    /*
      |-----------------------------------------------------------------------------
      | Text sizes                         https://tailwindcss.com/docs/text-sizing
      |-----------------------------------------------------------------------------
      |
      | Here is where you define your text sizes. Name these in whatever way
      | makes the most sense to you. We use size names by default, but
      | you're welcome to use a numeric scale or even something else
      | entirely.
      |
      | By default Tailwind uses the "rem" unit type for most measurements.
      | This allows you to set a root font size which all other sizes are
      | then based on. That said, you are free to use whatever units you
      | prefer, be it rems, ems, pixels or other.
      |
      | Class name: .text-{size}
      |
      */

    fontSize: {
      icon: '24px',
      'x-small': '11px',
      small: '14px',
      medium: '16px',
      large: '18px',
      'caption-m': '18px',
      caption: '18px',
      'hero-l': '100px',
      'hero-m': '85px',
      'hero-s': '50px',
      'h1-l': '70px',
      'h1-m': '50px',
      'h1-s': '35px',
      'h2-l': '55px',
      'h2-m': '40px',
      'h2-s': '30px',
      'h3-l': '40px',
      'h3-m': '30px',
      'h3-s': '24px',
      'h4-l': '24px',
      'h4-m': '20px',
      'h4-s': '18px',
      '9p': '9px',
      '17p': '17px'
    },

    /*
      |-----------------------------------------------------------------------------
      | Font weights                       https://tailwindcss.com/docs/font-weight
      |-----------------------------------------------------------------------------
      |
      | Here is where you define your font weights. We've provided a list of
      | common font weight names with their respective numeric scale values
      | to get you started. It's unlikely that your project will require
      | all of these, so we recommend removing those you don't need.
      |
      | Class name: .font-{weight}
      |
      */

    fontWeight: {
      thin: 100,
      extralight: 200,
      light: 300,
      normal: 400,
      medium: 500,
      bold: 600,
      extrabold: 800,
      black: 900
    },

    /*
      |-----------------------------------------------------------------------------
      | Leading (line height)              https://tailwindcss.com/docs/line-height
      |-----------------------------------------------------------------------------
      |
      | Here is where you define your line height values, or as we call
      | them in Tailwind, leadings.
      |
      | Class name: .leading-{size}
      |
      */

    lineHeight: {
      headline: '1.1',
      'headline-s': '1.2',
      'headline-xs': '1.3',
      body: '1.3',
      hero: '120px',
      'hero-m': '60px',
      h1: '72px',
      'h1-m': '42px',
      h2: '50px',
      'h2-m': '32px',
      h3: '25px',
      'h3-m': '24px',
      'h3-l': '40px',
      caption: '24px',
      'caption-m': '24px',
      small: '18px',
      'x-small': '16px',
      none: 1,
      '1em': '1em',
      '40p': '40px'
    },

    /*
      |-----------------------------------------------------------------------------
      | Tracking (letter spacing)       https://tailwindcss.com/docs/letter-spacing
      |-----------------------------------------------------------------------------
      |
      | Here is where you define your letter spacing values, or as we call
      | them in Tailwind, tracking.
      |
      | Class name: .tracking-{size}
      |
      */

    letterSpacing: {
      tighter: '0.05em',
      tight: '-0.025em',
      narrow: '-0.02em',
      normal: '0',
      wide: '0.025em',
      wider: '0.05em',
      widest: '0.1em'
    },

    /*
      |-----------------------------------------------------------------------------
      | Text colors                         https://tailwindcss.com/docs/text-color
      |-----------------------------------------------------------------------------
      |
      | Here is where you define your text colors. By default these use the
      | color palette we defined above, however you're welcome to set these
      | independently if that makes sense for your project.
      |
      | Class name: .text-{color}
      |
      */

    textColor: theme => theme('colors'),

    /*
      |-----------------------------------------------------------------------------
      | Background colors             https://tailwindcss.com/docs/background-color
      |-----------------------------------------------------------------------------
      |
      | Here is where you define your background colors. By default these use
      | the color palette we defined above, however you're welcome to set
      | these independently if that makes sense for your project.
      |
      | Class name: .bg-{color}
      |
      */

    backgroundColor: theme => theme('colors'),

    /*
      |-----------------------------------------------------------------------------
      | Border widths                     https://tailwindcss.com/docs/border-width
      |-----------------------------------------------------------------------------
      |
      | Here is where you define your border widths. Take note that border
      | widths require a special "default" value set as well. This is the
      | width that will be used when you do not specify a border width.
      |
      | Class name: .border{-side?}{-width?}
      |
      */

    borderWidth: {
      DEFAULT: '1px',
      0: '0',
      1: '1px',
      2: '2px',
      3: '3px',
      4: '4px',
      8: '8px'
    },

    /*
      |-----------------------------------------------------------------------------
      | Border colors                     https://tailwindcss.com/docs/border-color
      |-----------------------------------------------------------------------------
      |
      | Here is where you define your border colors. By default these use the
      | color palette we defined above, however you're welcome to set these
      | independently if that makes sense for your project.
      |
      | Take note that border colors require a special "default" value set
      | as well. This is the color that will be used when you do not
      | specify a border color.
      |
      | Class name: .border-{color}
      |
      */

    borderColor: theme => ({
      DEFAULT: theme('colors.grey-light'),
      ...theme('colors')
    }),

    /*
      |-----------------------------------------------------------------------------
      | Border radius                    https://tailwindcss.com/docs/border-radius
      |-----------------------------------------------------------------------------
      |
      | Here is where you define your border radius values. If a `default` radius
      | is provided, it will be made available as the non-suffixed `.rounded`
      | utility.
      |
      | If your scale includes a `0` value to reset already rounded corners, it's
      | a good idea to put it first so other values are able to override it.
      |
      | Class name: .rounded{-side?}{-size?}
      |
      */

    borderRadius: {
      none: '0',
      sm: '.125rem',
      DEFAULT: '.25rem',
      lg: '.5rem',
      full: '9999px',
      '3p': '3px',
      '20p': '20px'
    },

    /*
      |-----------------------------------------------------------------------------
      | Width                                    https://tailwindcss.com/docs/width
      |-----------------------------------------------------------------------------
      |
      | Here is where you define your width utility sizes. These can be
      | percentage based, pixels, rems, or any other units. By default
      | we provide a sensible rem based numeric scale, a percentage
      | based fraction scale, plus some other common use-cases. You
      | can, of course, modify these values as needed.
      |
      |
      | It's also worth mentioning that Tailwind automatically escapes
      | invalid CSS class name characters, which allows you to have
      | awesome classes like .w-2/3.
      |
      | Class name: .w-{size}
      |
      */

    width: {
      inherit: 'inherit',
      auto: 'auto',
      px: '1px',
      1: '0.25rem',
      2: '0.5rem',
      3: '0.75rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      8: '2rem',
      10: '2.5rem',
      12: '3rem',
      16: '4rem',
      24: '6rem',
      32: '8rem',
      36: '9rem',
      48: '12rem',
      64: '16rem',
      '1/2': '50%',
      '1/3': '33.33333%',
      '2/3': '66.66667%',
      '1/4': '25%',
      '3/4': '75%',
      '1/5': '20%',
      '2/5': '40%',
      '3/5': '60%',
      '4/5': '80%',
      '1/6': '16.66667%',
      '2/6': '33.3333%',
      '3/6': '50%',
      '4/6': '66.66667%',
      '5/6': '83.33333%',
      '1/12': '8.33%',
      '2/12': '16.66%',
      '3/12': '25%',
      '4/12': '33.33%',
      '5/12': '41.66%',
      '6/12': '50%',
      '7/12': '58.33%',
      '8/12': '66.66%',
      '9/12': '75%',
      '10/12': '83.33%',
      '11/12': '91.66%',
      '1/24': '4.1666667%',
      '2/24': '8.3333333%',
      '3/24': '12.5%',
      '4/24': '16.6666667%',
      '5/24': '20.8333333%',
      '6/24': '25%',
      '7/24': '29.1666667%',
      '8/24': '33.3333333%',
      '9/24': '37.5%',
      '10/24': '41.6666667%',
      '11/24': '45.8333333%',
      '12/24': '50%',
      '13/24': '54.1666667%',
      '14/24': '58.3333333%',
      '15/24': '62.5%',
      '16/24': '66.6666667%',
      '17/24': '70.8333333%',
      '18/24': '75%',
      '19/24': '79.1666667%',
      '20/24': '83.3333333%',
      '21/24': '87.5%',
      '22/24': '91.6666667%',
      '23/24': '95.8333333%',
      full: '100%',
      half: '50%',
      screen: '100vw',
      '14p': '14px',
      '18p': '18px',
      '19p': '19px',
      '20p': '20px',
      '22p': '22px',
      '23p': '23px',
      '25p': '25px',
      '35p': '35px',
      '26p': '26px',
      '40p': '40px',
      '45p': '45px',
      '50p': '50px',
      '60p': '60px',
      '64p': '64px',
      '70p': '70px',
      '80p': '80px',
      '85p': '85px',
      '90p': '90px',
      '100p': '100px',
      '117p': '117px',
      '120p': '120px',
      '130p': '130px',
      '150p': '150px',
      '180p': '180px',
      '182p': '182px',
      '200p': '200px',
      '212p': '212px',
      '230p': '230px',
      '260p': '260px',
      '280p': '280px',
      '300p': '300px',
      '400p': '400px',
      '500p': '500px'

    },

    /*
      |-----------------------------------------------------------------------------
      | Height                                  https://tailwindcss.com/docs/height
      |-----------------------------------------------------------------------------
      |
      | Here is where you define your height utility sizes. These can be
      | percentage based, pixels, rems, or any other units. By default
      | we provide a sensible rem based numeric scale plus some other
      | common use-cases. You can, of course, modify these values as
      | needed.
      |
      | Class name: .h-{size}
      |
      */

    height: {
      topBar: {
        sm: {
          usp: '30px',
          authenticated: '35px'
        },
        lg: {
          normal: '39px',
          authenticated: '39px'
        }
      },
      topMenuSpace: {
        sm: '80px',
        lg: '101px'
      },
      topMenu: {
        sm: '65px',
        lg: '81px'
      },
      searchFieldContainer: {
        sm: '67px',
        lg: '90px'
      },
      'checkout-progress': '95px',
      auto: 'auto',
      inherit: 'inherit',
      px: '1px',
      0: '0',
      1: '0.25rem',
      2: '0.5rem',
      3: '0.75rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      8: '2rem',
      10: '2.5rem',
      12: '3rem',
      16: '4rem',
      24: '6rem',
      32: '8rem',
      48: '12rem',
      64: '16rem',
      '18p': '18px',
      '19p': '19px',
      '20p': '20px',
      '22p': '22px',
      '23p': '23px',
      '25p': '25px',
      '26p': '26px',
      '32p': '32px',
      '34p': '34px',
      '35p': '35px',
      '40p': '40px',
      '45p': '45px',
      '50p': '50px',
      '60p': '60px',
      '64p': '64px',
      '70p': '70px',
      '90p': '90px',
      '100p': '100px',
      '120p': '120px',
      '175p': '175px',
      '250p': '250px',
      '350p': '350px',
      '440p': '440px',
      '575p': '575px',
      '700p': '700px',
      full: '100%',
      half: '50%',
      '1/3': '33.33%',
      '2/3': '66.66%',
      '1/4': '25%',
      '3/4': '75%',
      screen: '100vh'
    },

    /*
      |-----------------------------------------------------------------------------
      | Minimum width                        https://tailwindcss.com/docs/min-width
      |-----------------------------------------------------------------------------
      |
      | Here is where you define your minimum width utility sizes. These can
      | be percentage based, pixels, rems, or any other units. We provide a
      | couple common use-cases by default. You can, of course, modify
      | these values as needed.
      |
      | Class name: .min-w-{size}
      |
      */

    minWidth: {
      0: '0',
      10: '10%',
      50: '50%',
      '40p': '40px',
      '50p': '50px',
      '80p': '80px',
      '100p': '100px',
      '130p': '130px',
      '150p': '150px',
      '160p': '160px',
      '180p': '180px',
      '450p': '450px',
      full: '100%'
    },

    /*
      |-----------------------------------------------------------------------------
      | Minimum height                      https://tailwindcss.com/docs/min-height
      |-----------------------------------------------------------------------------
      |
      | Here is where you define your minimum height utility sizes. These can
      | be percentage based, pixels, rems, or any other units. We provide a
      | few common use-cases by default. You can, of course, modify these
      | values as needed.
      |
      | Class name: .min-h-{size}
      |
      */

    minHeight: {
      megaMenu: '70vh',
      searchResultPopoutContainer: '492px',
      0: '0',
      '30p': '30px',
      '52p': '52px',
      '57p': '57px',
      '68p': '68px',
      '258p': '258px',
      '288p': '288px',
      '334p': '334px',
      '400p': '400px',
      full: '100%',
      screen: '100vh'
    },

    /*
      |-----------------------------------------------------------------------------
      | Maximum width                        https://tailwindcss.com/docs/max-width
      |-----------------------------------------------------------------------------
      |
      | Here is where you define your maximum width utility sizes. These can
      | be percentage based, pixels, rems, or any other units. By default
      | we provide a sensible rem based scale and a "full width" size,
      | which is basically a reset utility. You can, of course,
      | modify these values as needed.
      |
      | Class name: .max-w-{size}
      |
      */

    maxWidth: {
      none: 'none',
      xs: '20rem',
      sm: '30rem',
      md: '40rem',
      lg: '50rem',
      xl: '60rem',
      '2xl': '70rem',
      '3xl': '80rem',
      '4xl': '90rem',
      '5xl': '100rem',
      half: '50%',
      '1/3': '33.33%',
      '2/3': '66.6%',
      '4/5': '80%',
      90: '90%',
      full: '100%',
      double: '200%',
      '50p': '50px',
      '60p': '60px',
      '90p': '90px',
      '100p': '100px',
      '130p': '130px',
      '160p': '160px',
      '180p': '180px',
      '250p': '250px',
      '270p': '270px',
      '300p': '300px',
      '400p': '400px',
      '450p': '450px',
      '550p': '550px',
      '700p': '700px',
      '750p': '750px',
      fit: 'fit-content'
    },

    /*
      |-----------------------------------------------------------------------------
      | Maximum height                      https://tailwindcss.com/docs/max-height
      |-----------------------------------------------------------------------------
      |
      | Here is where you define your maximum height utility sizes. These can
      | be percentage based, pixels, rems, or any other units. We provide a
      | couple common use-cases by default. You can, of course, modify
      | these values as needed.
      |
      | Class name: .max-h-{size}
      |
      */

    maxHeight: {
      full: '100%',
      screen: '100vh',
      '60p': '60px'
    },

    /*
      |-----------------------------------------------------------------------------
      | Padding                                https://tailwindcss.com/docs/padding
      |-----------------------------------------------------------------------------
      |
      | Here is where you define your padding utility sizes. These can be
      | percentage based, pixels, rems, or any other units. By default we
      | provide a sensible rem based numeric scale plus a couple other
      | common use-cases like "1px". You can, of course, modify these
      | values as needed.
      |
      | Class name: .p{side?}-{size}
      |
      */

    padding: {
      bodyContainerHorizontal: {
        sm: '1rem',
        lg: '2.5rem'
      },
      'empty-top-bar': '19px',
      'top-menu': '18px',
      'checkout-view-with-top-bar-padding': {
        sm: '25px',
        lg: '19px'
      },
      'checkout-progress': '38px',
      inherit: 'inherit',
      px: '1px',
      0: '0',
      1: '0.25rem',
      2: '0.5rem',
      3: '0.75rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      8: '2rem',
      10: '2.5rem',
      11: '2.75rem',
      12: '3rem',
      14: '3.5rem',
      16: '4rem',
      18: '4.5rem',
      20: '5rem',
      22: '5.5rem',
      24: '6rem',
      26: '6.5rem',
      28: '7rem',
      30: '7.5rem',
      '1/4': '25%',
      '1/2': '50%',
      full: '100%',
      '1/3': '33.33333%',
      '2p': '2px',
      '4p': '4px',
      '5p': '5px',
      '6p': '6px',
      '7p': '7px',
      '10p': '10px',
      '13p': '13px',
      '15p': '15px',
      '16p': '16px',
      '20p': '20px',
      '25p': '25px',
      '27p': '27px',
      '30p': '30px',
      '40p': '40px',
      '45p': '45px',
      '50p': '50px',
      '55p': '55px',
      '60p': '60px',
      '64p': '64px',
      '70p': '70px',
      '80p': '80px',
      '90p': '90px',
      '100p': '100px',
      '120p': '120px',
      '130p': '130px',
      '140p': '140px',
      '250p': '250px',
      'block-space': '120px'
    },

    /*
      |-----------------------------------------------------------------------------
      | Margin                                  https://tailwindcss.com/docs/margin
      |-----------------------------------------------------------------------------
      |
      | Here is where you define your margin utility sizes. These can be
      | percentage based, pixels, rems, or any other units. By default we
      | provide a sensible rem based numeric scale plus a couple other
      | common use-cases like "1px". You can, of course, modify these
      | values as needed.
      |
      | Class name: .m{side?}-{size}
      |
      */

    margin: {
      auto: 'auto',
      px: '1px',
      0: '0',
      1: '0.25rem',
      2: '0.5rem',
      3: '0.75rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      7: '1.75rem',
      8: '2rem',
      10: '2.5rem',
      11: '2.75rem',
      12: '3rem',
      14: '3.5rem',
      15: '3.75rem',
      16: '4rem',
      18: '4.5rem',
      20: '5rem',
      22: '5.5rem',
      25: '25px',
      50: '50px',
      80: '80px',
      100: '100px',
      110: '110px',
      120: '120px',
      150: '150px',
      180: '180px',
      '1p': '1px',
      '2p': '2px',
      '4p': '4px',
      '5p': '5px',
      '6p': '6px',
      '7p': '7px',
      '8p': '8px',
      '10p': '10px',
      '13p': '13px',
      '14p': '14px',
      '15p': '15px',
      '16p': '16px',
      '20p': '20px',
      '27p': '27px',
      '30p': '30px',
      '34p': '34px',
      '40p': '40px',
      '45p': '45px',
      '50p': '50px',
      '55p': '55px',
      '60p': '60px',
      '70p': '70px',
      '80p': '80px',
      '90p': '90px',
      '100p': '100px',
      '120p': '120px',
      '150p': '150px',
      'block-space': '120px',
      'gutter-1': '5px',
      'gutter-2': '25px',
      '-px': '-1px',
      '-1': '-0.25rem',
      '-2': '-0.5rem',
      '-3': '-0.75rem',
      '-4': '-1rem',
      '-6': '-1.5rem',
      '-8': '-2rem',
      '-16': '-4rem',
      '-100': '-100px',
      '-2p': '-2px',
      '-4p': '-4px',
      '-5p': '-5px',
      '-6p': '-6px',
      '-10p': '-10px',
      '-15p': '-15px',
      '-20p': '-20px',
      '-24p': '-24px',
      '-25p': '-25px',
      '-30p': '-30px',
      '-50p': '-50px',
      '-80p': '-80px',
      '-200p': '-200px'
    },

    /*
      |-----------------------------------------------------------------------------
      | Shadows                                https://tailwindcss.com/docs/shadows
      |-----------------------------------------------------------------------------
      |
      | Here is where you define your shadow utilities. As you can see from
      | the defaults we provide, it's possible to apply multiple shadows
      | per utility using comma separation.
      |
      | If a `default` shadow is provided, it will be made available as the non-
      | suffixed `.shadow` utility.
      |
      | Class name: .shadow-{size?}
      |
      */

    boxShadow: {
      DEFAULT: '0 2px 4px 0 rgba(0,0,0,0.10)',
      md: '0 4px 8px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.08)',
      lg: '0 15px 30px 0 rgba(0,0,0,0.11), 0 5px 15px 0 rgba(0,0,0,0.08)',
      inner: 'inset 0 2px 4px 0 rgba(0,0,0,0.06)',
      none: 'none',
      'mb-4': 'inset 0 -4px 0 0 black',
      'b-2': 'inset 0 0 0 2px black',
      '20p': '0 0 20px 0 rgba(0,0,0,0.1)',
      'b-6-light': '0 0 6px 0 rgba(0, 0, 0, 0.10)'
    },

    /*
      |-----------------------------------------------------------------------------
      | Z-index                                https://tailwindcss.com/docs/z-index
      |-----------------------------------------------------------------------------
      |
      | Here is where you define your z-index utility values. By default we
      | provide a sensible numeric scale. You can, of course, modify these
      | values as needed.
      |
      | Class name: .z-{index}
      |
      */

    zIndex: {
      auto: 'auto',
      '-1': -1,
      0: 0,
      1: 1,
      2: 2,
      3: 3,
      10: 10,
      20: 20,
      30: 30,
      40: 40,
      50: 50,
      60: 60,
      80: 80,
      90: 90,
      91: 91,
      92: 92,
      100: 100,
      120: 120,
      200: 200,
      err: 250
    },

    /*
      |-----------------------------------------------------------------------------
      | Opacity                                https://tailwindcss.com/docs/opacity
      |-----------------------------------------------------------------------------
      |
      | Here is where you define your opacity utility values. By default we
      | provide a sensible numeric scale. You can, of course, modify these
      | values as needed.
      |
      | Class name: .opacity-{name}
      |
      */

    opacity: {
      0: '0',
      25: '.25',
      40: '.4',
      50: '.5',
      75: '.75',
      85: '.85',
      98: '.98',
      100: '1'
    },

    scale: {
      80: '.8'
    },

    /*
      |-----------------------------------------------------------------------------
      | SVG fill                                   https://tailwindcss.com/docs/svg
      |-----------------------------------------------------------------------------
      |
      | Here is where you define your SVG fill colors. By default we just provide
      | `fill-current` which sets the fill to the current text color. This lets you
      | specify a fill color using existing text color utilities and helps keep the
      | generated CSS file size down.
      |
      | Class name: .fill-{name}
      |
      */

    fill: {
      current: 'currentColor'
    },

    /*
      |-----------------------------------------------------------------------------
      | SVG stroke                                 https://tailwindcss.com/docs/svg
      |-----------------------------------------------------------------------------
      |
      | Here is where you define your SVG stroke colors. By default we just provide
      | `stroke-current` which sets the stroke to the current text color. This lets
      | you specify a stroke color using existing text color utilities and helps
      | keep the generated CSS file size down.
      |
      | Class name: .stroke-{name}
      |
      */

    stroke: {
      current: 'currentColor'
    },

    gap: {
      bodyGridColumn: {
        sm: '1.5vw',
        lg: '1.25vw'
      },
      0: '0',
      '5p': '5px',
      '10p': '10px',
      '12p': '12px',
      '15p': '15px',
      '20p': '20px',
      '40p': '40px',
      '50p': '50px',
      '60p': '60px',
      '80p': '80px'
    },
    extend: {
      gridColumnEnd: {
        'span-3': 'span 3',
        'span-5': 'span 5',
        'span-9': 'span 9'
      }
    }
  },

  /*
    |-----------------------------------------------------------------------------
    | Modules                  https://tailwindcss.com/docs/configuration#modules
    |-----------------------------------------------------------------------------
    |
    | Here is where you control which modules are generated and what variants are
    | generated for each of those modules.
    |
    | Currently supported variants: 'responsive', 'hover', 'focus'
    |
    | To disable a module completely, use `false` instead of an array.
    |
    */
  variants: {
    alignContent: ['responsive'],
    alignItems: ['responsive'],
    alignSelf: ['responsive'],
    appearance: ['responsive'],
    backgroundAttachment: ['responsive'],
    backgroundColor: ['responsive', 'hover'],
    backgroundPosition: ['responsive'],
    backgroundRepeat: ['responsive'],
    backgroundSize: ['responsive'],
    borderColor: ['responsive', 'hover'],
    borderRadius: ['responsive'],
    borderStyle: ['responsive'],
    borderWidth: ['responsive', 'hover'],
    boxShadow: ['responsive', 'hover'],
    cursor: ['responsive'],
    display: ['responsive', 'group-hover'],
    fill: [],
    flex: ['responsive'],
    flexDirection: ['responsive'],
    flexGrow: ['responsive'],
    flexShrink: ['responsive'],
    flexWrap: ['responsive'],
    float: ['responsive'],
    fontFamily: ['responsive', 'hover'],
    fontSize: ['responsive', 'hover'],
    fontSmoothing: ['responsive', 'hover'],
    fontStyle: ['responsive', 'hover'],
    fontWeight: ['responsive', 'hover', 'focus'],
    height: ['responsive'],
    IntersectionObserver: ['responsive'],
    justifyContent: ['responsive'],
    letterSpacing: ['responsive'],
    lineHeight: ['responsive'],
    listStylePosition: ['responsive'],
    listStyleType: ['responsive'],
    margin: ['responsive'],
    maxHeight: ['responsive'],
    maxWidth: ['responsive'],
    minHeight: ['responsive'],
    minWidth: ['responsive'],
    negativeMargin: ['responsive'],
    opacity: ['responsive', 'hover'],
    overflow: ['responsive'],
    padding: ['responsive'],
    pointerEvents: ['responsive'],
    position: ['responsive'],
    resize: ['responsive'],
    stroke: [],
    textAlign: ['responsive'],
    textColor: ['responsive', 'hover'],
    textDecoration: ['responsive', 'hover'],
    textTransform: ['responsive', 'hover'],
    userSelect: ['responsive'],
    verticalAlign: ['responsive'],
    visibility: ['responsive'],
    whitespace: ['responsive'],
    wordBreak: ['responsive'],
    width: ['responsive'],
    zIndex: ['responsive']
  },
  corePlugins: {
    container: false
  }
};
